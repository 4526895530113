import "../css/board.css";
import Chip from "./Chip";
import Puck from "./Puck";
import data from "./data";
import RollDice from "./RollDice";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

const Board = forwardRef((props, ref) => {
   const dice = useRef();
   const [puck, setPuck] = useState(0);
   const [stacks, setStacks] = useState(data);
   const [message, setMessage] = useState("");
   const [betWorking, setBetWorking] = useState(false);

   const rolling = useRef(false);

   useImperativeHandle(ref, () => ({
      async diceRoll() {
         if (rolling.current) return;
         rolling.current = true;

         let { val, vals } = await dice.current.diceRoll();

         let wins = 0;

         wins += await hardLogic(val, vals);
         wins += await fieldsLogic(val);
         wins += await comesLogic(val);
         wins += await passLineLogic(val);

         props.setWins(wins);
         props.setBalance((bal) => bal + wins);
         calculateWager();

         rolling.current = false;
      },
      clearBets() {
         let wages = 0;
         for (let [key, stack] of Object.entries(stacks)) {
            if (stack.betable && stack.bet > 0) {
               wages += stack.bet;
               stack.bet = 0;
               setStacks((stacks) => ({ ...stacks, [key]: stack }));
            }
         }
         props.setBalance((bal) => bal + wages);
         calculateWager();
      },
   }));

   async function hardLogic(val, vals) {
      let wins = 0;
      let working = betWorking || puck > 0;
      if (val === 10 || val === 11) {
         let hardStacks = {};
         let hard1011 = stacks.hard1011;

         if (hard1011.bet > 0) {
            wins += hard1011.bet * 3;
            await animateChips(hard1011, true);
         }

         if (working) {
            hardStacks = (({ hard1011, hard111, hard222, hard333, hard444, hard555, hard666, anyCraps }) => ({
               hard1011,
               hard111,
               hard222,
               hard333,
               hard444,
               hard555,
               hard666,
               anyCraps,
            }))(stacks);
         } else {
            hardStacks = (({ hard1011, hard111, hard666, anyCraps }) => ({
               hard1011,
               hard111,
               hard666,
               anyCraps,
            }))(stacks);
         }
         for (let stack of Object.values(hardStacks)) {
            if (stack.betable && stack.bet > 0) {
               await animateChips(stack, false);
            }
         }
      } else {
         let hard1011 = stacks.hard1011;
         if (hard1011.bet > 0) {
            await animateChips(hard1011, false);
         }
      }

      if (working) {
         if (val === 6) {
            if (vals.toString() === "2,2,2") {
               let hard222 = stacks.hard222;
               if (hard222.bet > 0) {
                  wins += hard222.bet * 12;
                  await animateChips(hard222, true);
               }
            } else {
               let hard222 = stacks.hard222;
               if (hard222.bet > 0) {
                  await animateChips(hard222, false);
               }
            }
         }

         if (val === 15) {
            if (vals.toString() === "5,5,5") {
               let hard555 = stacks.hard555;
               if (hard555.bet > 0) {
                  wins += hard555.bet * 12;
                  await animateChips(hard555, true);
               }
            } else {
               let hard555 = stacks.hard555;
               if (hard555.bet > 0) {
                  await animateChips(hard555, false);
               }
            }
         }

         if (val === 9) {
            if (vals.toString() === "3,3,3") {
               let hard333 = stacks.hard333;
               if (hard333.bet > 0) {
                  wins += hard333.bet * 14;
                  await animateChips(hard333, true);
               }
            } else {
               let hard333 = stacks.hard333;
               if (hard333.bet > 0) {
                  await animateChips(hard333, false);
               }
            }
         }

         if (val === 12) {
            if (vals.toString() === "4,4,4") {
               let hard444 = stacks.hard444;
               if (hard444.bet > 0) {
                  wins += hard444.bet * 14;
                  await animateChips(hard444, true);
               }
            } else {
               let hard444 = stacks.hard444;
               if (hard444.bet > 0) {
                  await animateChips(hard444, false);
               }
            }
         }
      }

      if (vals.toString() === "1,1,1") {
         let hard111 = stacks.hard111;
         if (hard111.bet > 0) {
            wins += hard111.bet * 50;
            await animateChips(hard111, true);
         }
      } else {
         let hard111 = stacks.hard111;
         if (hard111.bet > 0) {
            await animateChips(hard111, false);
         }
      }

      if (vals.toString() === "6,6,6") {
         let hard666 = stacks.hard666;
         if (hard666.bet > 0) {
            wins += hard666.bet * 50;
            await animateChips(hard666, true);
         }
      } else {
         let hard666 = stacks.hard666;
         if (hard666.bet > 0) {
            await animateChips(hard666, false);
         }
      }

      if (
         vals.toString() === "1,1,1" ||
         vals.toString() === "2,2,2" ||
         vals.toString() === "3,3,3" ||
         vals.toString() === "4,4,4" ||
         vals.toString() === "5,5,5" ||
         vals.toString() === "6,6,6"
      ) {
         let anyCraps = stacks.anyCraps;
         if (anyCraps.bet > 0) {
            wins += anyCraps.bet * 7;
            await animateChips(anyCraps, true);
         }
      } else {
         let anyCraps = stacks.anyCraps;
         if (anyCraps.bet > 0) {
            await animateChips(anyCraps, false);
         }
      }
      return wins;
   }

   async function fieldsLogic(val) {
      let wins = 0;
      let fields = [3, 4, 5, 6, 7, 13, 14, 15, 16, 17, 18];
      if (fields.includes(val)) {
         let field = stacks.field;
         if (val === 18) {
            wins += field.bet * 6;
         } else if (val === 2) {
            wins += field.bet * 3;
         } else {
            wins += field.bet * 2;
         }
         if (field.bet > 0) {
            await animateChips(field, true);
         }
      } else {
         let field = stacks.field;
         if (field.bet > 0) {
            await animateChips(field, false);
         }
      }
      return wins;
   }

   async function comesLogic(val) {
      let wins = 0;
      let craps = [10, 11];
      let dontCraps = [3, 4, 17, 18];
      let points = [5, 6, 7, 8, 9, 12, 13, 14, 15, 16];

      if (dontCraps.includes(val)) {
         let come = stacks.come;
         if (come.bet > 0) {
            await animateChips(come, false);
         }
      } else if (craps.includes(val)) {
         let come = stacks.come;
         if (come.bet > 0) {
            wins += come.bet * 2;
            await animateChips(come, true);
         }

         for (let k of points) {
            let csk = "come" + k;
            let csok = "come" + k + "Odds";
            let comeStack = stacks[csk];
            let comeStackOdds = stacks[csok];

            if (comeStack.bet > 0) {
               await animateChips(comeStack, false);
            }

            if (comeStackOdds.bet > 0) {
               await animateChips(comeStackOdds, false);
            }

            comeStackOdds.maxbet = 0;
            comeStackOdds.betable = false;
            setStacks((stacks) => ({ ...stacks, [csok]: comeStackOdds }));
         }
      } else if (points.includes(val)) {
         let come = stacks.come;
         let csk = "come" + val;
         let csok = "come" + val + "Odds";
         let comeStack = stacks[csk];
         let comeStackOdds = stacks[csok];

         if (comeStack.bet > 0 && (betWorking || puck > 0)) {
            wins += comeStack.bet * 2;
            await animateChips(comeStack, true);

            if (comeStackOdds.bet > 0) {
               if ([5, 16].includes(val)) {
                  wins += comeStackOdds.bet * 8;
               } else if ([6, 15].includes(val)) {
                  wins += comeStackOdds.bet * 5;
               } else if ([7, 14].includes(val)) {
                  wins += comeStackOdds.bet * 3;
               } else if ([8, 13].includes(val)) {
                  wins += comeStackOdds.bet * 2.5;
               } else if ([9, 12].includes(val)) {
                  wins += comeStackOdds.bet * 2;
               }

               comeStackOdds.maxbet = 0;
               comeStackOdds.betable = false;
               await animateChips(comeStackOdds, true);
            }
         }

         if (come.bet > 0 && (betWorking || puck > 0)) {
            comeStack.bet = come.bet;
            come.bet = 0;
            comeStackOdds.betable = true;
            if ([5, 6, 15, 16].includes(val)) {
               comeStackOdds.maxbet = comeStack.bet * 3;
            } else if ([7, 8, 13, 14].includes(val)) {
               comeStackOdds.maxbet = comeStack.bet * 4;
            } else if ([9, 12].includes(val)) {
               comeStackOdds.maxbet = comeStack.bet * 5;
            }
         }

         setStacks((stacks) => ({ ...stacks, [csk]: comeStack, [csok]: comeStackOdds }));
      }
      return wins;
   }

   async function passLineLogic(val) {
      let _puck = puck;
      let wins = 0;
      let craps = [10, 11];
      let fail = [3, 4, 17, 18];
      let points = [5, 6, 7, 8, 9, 12, 13, 14, 15, 16];

      if (puck === 0) {
         if (points.includes(val)) {
            _puck = val;
            let come = stacks.come;
            let passLine = stacks.passLine;
            let passLineOdds = stacks.passLineOdds;

            come.betable = true;
            passLine.betable = false;
            passLineOdds.betable = true;
            passLineOdds.maxbet = stacks.passLine.bet * 5;
            setStacks((stacks) => ({ ...stacks, come, passLine, passLineOdds }));

            let comeOdds = (({ come5Odds, come6Odds, come7Odds, come8Odds, come9Odds, come12Odds, come13Odds, come14Odds, come15Odds, come16Odds }) => ({
               come5Odds,
               come6Odds,
               come7Odds,
               come8Odds,
               come9Odds,
               come12Odds,
               come13Odds,
               come14Odds,
               come15Odds,
               come16Odds,
            }))(stacks);

            for (let [key, stack] of Object.entries(comeOdds)) {
               stack.betable = stack.maxbet > 0 && (betWorking || _puck);
               setStacks((stacks) => ({ ...stacks, [key]: stack }));
            }
         } else if (fail.includes(val)) {
            let passLine = stacks.passLine;
            if (passLine.bet > 0) {
               await animateChips(passLine, false);
               setStacks((stacks) => ({ ...stacks, passLine }));
            }
         } else if (craps.includes(val)) {
            let passLine = stacks.passLine;
            if (passLine.bet > 0) {
               wins += passLine.bet * 2;
               await animateChips(passLine, true);
            }
         }
      } else {
         if (puck === val) {
            _puck = 0;
            let come = stacks.come;
            let passLine = stacks.passLine;
            let passLineOdds = stacks.passLineOdds;

            if (passLine.bet > 0) {
               wins += passLine.bet * 2;
               await animateChips(passLine, true);
            }

            if (passLineOdds.bet > 0) {
               wins += passLineOdds.bet * 2.5;
               await animateChips(passLineOdds, true);
            }

            come.betable = false;
            passLine.betable = true;
            passLineOdds.betable = false;
            setStacks((stacks) => ({ ...stacks, come, passLine, passLineOdds }));

            let comeOdds = (({ come5Odds, come6Odds, come7Odds, come8Odds, come9Odds, come12Odds, come13Odds, come14Odds, come15Odds, come16Odds }) => ({
               come5Odds,
               come6Odds,
               come7Odds,
               come8Odds,
               come9Odds,
               come12Odds,
               come13Odds,
               come14Odds,
               come15Odds,
               come16Odds,
            }))(stacks);

            for (let [key, stack] of Object.entries(comeOdds)) {
               stack.betable = stack.maxbet > 0 && (betWorking || _puck);
               setStacks((stacks) => ({ ...stacks, [key]: stack }));
            }
         } else if (craps.includes(val)) {
            _puck = 0;
            let come = stacks.come;
            let passLine = stacks.passLine;
            let passLineOdds = stacks.passLineOdds;

            if (passLine.bet > 0) {
               await animateChips(passLine, false);
            }

            if (passLineOdds.bet > 0) {
               await animateChips(passLineOdds, false);
            }

            come.betable = false;
            passLine.betable = true;
            passLineOdds.betable = false;
            setStacks((stacks) => ({ ...stacks, come, passLine, passLineOdds }));
         }
      }

      let hardStacks = (({ hard222, hard333, hard444, hard555 }) => ({ hard222, hard333, hard444, hard555 }))(stacks);

      for (let [key, stack] of Object.entries(hardStacks)) {
         stack.betable = betWorking || _puck;
         setStacks((stacks) => ({ ...stacks, [key]: stack }));
      }

      setPuck(_puck);

      return wins;
   }

   async function animateChips(stack, win = true) {
      let winPos = { top: 425, left: 75 };
      let losPos = { top: 0, left: 800 };
      let orgPos = { top: stack.top, left: stack.left };
      let key = stack.key;

      if (win) {
         stack.top = winPos.top;
         stack.left = winPos.left;
      } else {
         stack.top = losPos.top;
         stack.left = losPos.left;
      }

      setStacks((stacks) => ({ ...stacks, [key]: stack }));

      await timeout(200);

      stack.bet = 0;
      stack.top = orgPos.top;
      stack.left = orgPos.left;

      setStacks((stacks) => ({ ...stacks, [key]: stack }));
      return;
   }

   function timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
   }

   function mouseOverEvent(event) {
      let stack = stacks[event.currentTarget.id];
      if (stack.betable) {
         event.currentTarget.style.backgroundColor = "#0f05";
      } else {
         event.currentTarget.style.backgroundColor = "#f005";
      }
      setMessage(event.currentTarget.id);
   }

   function mouseLeaveEvent(event) {
      event.currentTarget.style.backgroundColor = "#0000";
      setMessage("");
   }

   function addStackBet(event) {
      if (!rolling.current) {
         let stack = stacks[event.currentTarget.id];
         if (event.shiftKey) {
            if (stack.betable && stack.bet - props.chip >= 0) {
               stack.bet -= props.chip;
               stacks[event.currentTarget.id] = stack;
               props.setBalance((bal) => bal + props.chip);
               setStacks(stacks);
               calculateWager();
            }
         } else {
            if (stack.betable && stack.bet + props.chip <= stack.maxbet && props.chip <= props.balance) {
               stack.bet += props.chip;
               stacks[event.currentTarget.id] = stack;
               props.setBalance((bal) => bal - props.chip);
               setStacks(stacks);
               calculateWager();
            }
         }
      }
   }

   function calculateWager() {
      let wager = 0;
      for (let stack of Object.values(stacks)) {
         if (stack.bet > 0) {
            wager += stack.bet;
         }
      }
      props.setWager(wager);
   }

   function leaveBetWorking(event) {
      let checked = event.target.checked;
      let hardStacks = (({ hard222, hard333, hard444, hard555 }) => ({
         hard222,
         hard333,
         hard444,
         hard555,
      }))(stacks);

      for (let [key, stack] of Object.entries(hardStacks)) {
         stack.betable = checked || puck > 0;
         setStacks((stacks) => ({ ...stacks, [key]: stack }));
      }

      let comeOdds = (({ come5Odds, come6Odds, come7Odds, come8Odds, come9Odds, come12Odds, come13Odds, come14Odds, come15Odds, come16Odds }) => ({
         come5Odds,
         come6Odds,
         come7Odds,
         come8Odds,
         come9Odds,
         come12Odds,
         come13Odds,
         come14Odds,
         come15Odds,
         come16Odds,
      }))(stacks);

      for (let [key, stack] of Object.entries(comeOdds)) {
         stack.betable = stack.maxbet > 0 && (checked || puck > 0);
         setStacks((stacks) => ({ ...stacks, [key]: stack }));
      }
      setBetWorking(checked);
   }

   function renderChips(stack) {
      let lockStacks = [
         "hard222",
         "hard333",
         "hard444",
         "hard555",
         "come5Odds",
         "come6Odds",
         "come7Odds",
         "come8Odds",
         "come9Odds",
         "come12Odds",
         "come13Odds",
         "come14Odds",
         "come15Odds",
         "come16Odds",
      ];
      let lock = lockStacks.includes(stack.key);
      let off = lock ? !betWorking && puck <= 0 : false;

      let bet = stack.bet;
      let chips = [];

      let bet1000 = Math.floor(bet / 1000);
      for (let i = 0; i < bet1000; i++) {
         chips.push(1000);
      }
      bet = bet % 1000;
      let bet500 = Math.floor(bet / 500);
      for (let i = 0; i < bet500; i++) {
         chips.push(500);
      }
      bet = bet % 500;
      let bet100 = Math.floor(bet / 100);
      for (let i = 0; i < bet100; i++) {
         chips.push(100);
      }
      bet = bet % 100;
      let bet25 = Math.floor(bet / 25);
      for (let i = 0; i < bet25; i++) {
         chips.push(25);
      }
      bet = bet % 25;
      let bet5 = Math.floor(bet / 5);
      for (let i = 0; i < bet5; i++) {
         chips.push(5);
      }
      bet = bet % 5;
      for (let i = 0; i < bet; i++) {
         chips.push(1);
      }

      return chips.map((chip, index) => <Chip key={index} top={stack.top + -(index * 5)} left={stack.left} value={chip} off={off} />);
   }

   function renderPuck(pos) {
      if (puck === pos) {
         return <Puck top={5} left={pos === 0 ? 5 : 18} value={pos} />;
      } else {
         return null;
      }
   }

   return (
      <div className="board">
         <div className="leave-bet-row">
            <div className="leave-bet-box">
               <input type="checkbox" id="leave_bet" onChange={leaveBetWorking} checked={betWorking} />
               <label htmlFor="leave_bet">Leave bets working</label>
            </div>
         </div>
         <div className="board-box">
            {Object.values(stacks).map((stack) => renderChips(stack))}
            <RollDice ref={dice} />
            {renderPuck(0)}
            <div className="puck-row">
               <div className="puck-box">{renderPuck(5)}</div>
               <div className="puck-box">{renderPuck(6)}</div>
               <div className="puck-box">{renderPuck(7)}</div>
               <div className="puck-box">{renderPuck(8)}</div>
               <div className="puck-box">{renderPuck(9)}</div>
               <div className="puck-box">{renderPuck(12)}</div>
               <div className="puck-box">{renderPuck(13)}</div>
               <div className="puck-box">{renderPuck(14)}</div>
               <div className="puck-box">{renderPuck(15)}</div>
               <div className="puck-box">{renderPuck(16)}</div>
            </div>
            <div className="num-row">
               <div className="num-box">
                  <div id="lay5" onClick={addStackBet} onMouseEnter={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay5, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">5</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come5" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come5, -10, -10)} */}
                           </div>
                           <div id="come5Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come5Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place5" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place5, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay6" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay6, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">6</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come6" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come6, -10, -10)} */}
                           </div>
                           <div id="come6Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come6Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place6" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place6, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay7" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay7, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">7</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come7" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come7, -10, -10)} */}
                           </div>
                           <div id="come7Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come7Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place7" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place7, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay8" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay8, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">8</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come8" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come8, -10, -10)} */}
                           </div>
                           <div id="come8Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come8Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place8" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place8, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay9" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay9, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">9</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come9" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come9, -10, -10)} */}
                           </div>
                           <div id="come9Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come9Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place9" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place9, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay12" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay12, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">12</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come12" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come12, -10, -10)} */}
                           </div>
                           <div id="come12Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come12Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place12" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place12, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay13" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay13, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">13</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come13" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come13, -10, -10)} */}
                           </div>
                           <div id="come13Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come13Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place13" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place13, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay14" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay14, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">14</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come14" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come14, -10, -10)} */}
                           </div>
                           <div id="come14Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come14Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place14" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place14, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay15" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay15, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">15</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come15" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come15, -10, -10)} */}
                           </div>
                           <div id="come15Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come15Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place15" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place15, 20, 7)} */}
                  </div>
               </div>
               <div className="num-box">
                  <div id="lay16" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="lay-stack">
                     {/* {renderChips(stacks.lay16, 20, 7)} */}
                  </div>
                  <div className="come-stack">
                     <div className="come-stack-num">16</div>
                     <div className="come-stack-box">
                        <div className="come-stack-row">
                           <div id="come16" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come16, -10, -10)} */}
                           </div>
                           <div id="come16Odds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent}>
                              {/* {renderChips(stacks.come16Odds, -10, -10)} */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="place16" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="place-stack">
                     {/* {renderChips(stacks.place16, 20, 7)} */}
                  </div>
               </div>
            </div>
            <div className="center-box">
               <div className="left-side">
                  <div className="come-row">
                     <div id="come" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="come-stack">
                        {/* {renderChips(stacks.come, -20, 30)} */}
                        COME
                     </div>
                  </div>
                  <div className="field-row">
                     <div id="field" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="field-stack">
                        {/* {renderChips(stacks.field, -20, 360)} */}
                        <div className="field-nums">
                           <span className="field-nums-2">
                              3<sup>3x</sup>
                           </span>
                           , 4, 5, 6, 7, 13, 14, 15, 16, 17,{" "}
                           <span className="field-nums-2">
                              18<sup>6x</sup>
                           </span>
                        </div>
                        <div className="field-name">FIELD</div>
                     </div>
                  </div>
                  <div className="pass-line-row">
                     <div id="passLine" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="pass-line">
                        {/* {renderChips(stacks.passLine, 5, 30)} */}
                        PASS LINE
                     </div>
                  </div>
                  <div className="pass-line-odds-row">
                     <div id="passLineOdds" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="pass-line">
                        {/* {renderChips(stacks.passLineOdds, 20, 100)} */}
                     </div>
                  </div>
               </div>
               <div className="right-side">
                  <div className="hard-box">
                     <div className="hard-row">
                        <div id="hard1011" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="hard-item">
                           <div className="hard-text hardest">10 or 11</div> <div className="hard-info hardest"> 3 to 1 </div>
                           {/* {renderChips(stacks.hard1011, 0, 25)} */}
                        </div>
                     </div>
                     <div className="hard-row">
                        <div id="hard222" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="hard-item">
                           <div className="hard-text harder">2, 2, 2</div>
                           <div className="hard-info harder"> 12 to 1 </div>
                           {/* {renderChips(stacks.hard222, 0, 25, !betWorking && puck <= 0)} */}
                        </div>
                        <div id="hard555" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="hard-item">
                           <div className="hard-text harder">5, 5, 5</div>
                           <div className="hard-info harder"> 12 to 1 </div>
                           {/* {renderChips(stacks.hard555, 0, 25, !betWorking && puck <= 0)} */}
                        </div>
                     </div>
                     <div className="hard-row">
                        <div id="hard333" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="hard-item">
                           <div className="hard-text harder">3, 3, 3</div> <div className="hard-info harder"> 14 to 1 </div>
                           {/* {renderChips(stacks.hard333, 0, 25, !betWorking && puck <= 0)} */}
                        </div>
                        <div id="hard444" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="hard-item">
                           <div className="hard-text harder">4, 4, 4</div> <div className="hard-info harder"> 14 to 1 </div>
                           {/* {renderChips(stacks.hard444, 0, 25, !betWorking && puck <= 0)} */}
                        </div>
                     </div>
                     <div className="hard-row">
                        <div id="hard111" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="hard-item">
                           <div className="hard-text hardest">1, 1, 1</div> <div className="hard-info hardest"> 50 to 1 </div>
                           {/* {renderChips(stacks.hard111, 0, 25)} */}
                        </div>
                        <div id="hard666" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="hard-item">
                           <div className="hard-text hardest">6, 6, 6</div> <div className="hard-info hardest"> 50 to 1 </div>
                           {/* {renderChips(stacks.hard666, 0, 25)} */}
                        </div>
                     </div>
                     <div className="hard-row">
                        <div id="anyCraps" onClick={addStackBet} onMouseOver={mouseOverEvent} onMouseLeave={mouseLeaveEvent} className="hard-item">
                           <div className="hard-text hardest"> Any Craps </div>
                           <div className="hard-info hardest"> 7 to 1 </div>
                           {/* {renderChips(stacks.anyCraps, 0, 127)} */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="message-row">
               <div className="message">
                  {message
                     .replace(/[^0-9](?=[0-9])/g, "$& ")
                     .replace(/([A-Z])/g, " $1")
                     .trim()}
               </div>
            </div>
         </div>
      </div>
   );
});

export default Board;
