let data = {
   lay5: {
      key: "lay5",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay6: {
      key: "lay6",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay7: {
      key: "lay7",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay8: {
      key: "lay8",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay9: {
      key: "lay9",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay12: {
      key: "lay12",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay13: {
      key: "lay13",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay14: {
      key: "lay14",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay15: {
      key: "lay15",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   lay16: {
      key: "lay16",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place5: {
      key: "place5",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place6: {
      key: "place6",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place7: {
      key: "place7",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place8: {
      key: "place8",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place9: {
      key: "place9",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place12: {
      key: "place12",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place13: {
      key: "place13",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place14: {
      key: "place14",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place15: {
      key: "place15",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   place16: {
      key: "place16",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   come5: {
      key: "come5",
      bet: 0,
      top: 75,
      left: 30,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come6: {
      key: "come6",
      bet: 0,
      top: 75,
      left: 102,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come7: {
      key: "come7",
      bet: 0,
      top: 75,
      left: 174,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come8: {
      key: "come8",
      bet: 0,
      top: 75,
      left: 246,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come9: {
      key: "come9",
      bet: 0,
      top: 75,
      left: 318,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come12: {
      key: "come12",
      bet: 0,
      top: 75,
      left: 390,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come13: {
      key: "come13",
      bet: 0,
      top: 75,
      left: 462,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come14: {
      key: "come14",
      bet: 0,
      top: 75,
      left: 534,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come15: {
      key: "come15",
      bet: 0,
      top: 75,
      left: 606,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come16: {
      key: "come16",
      bet: 0,
      top: 75,
      left: 678,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come5Odds: {
      key: "come5Odds",
      bet: 0,
      top: 75,
      left: 70,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come6Odds: {
      key: "come6Odds",
      bet: 0,
      top: 75,
      left: 142,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come7Odds: {
      key: "come7Odds",
      bet: 0,
      top: 75,
      left: 214,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come8Odds: {
      key: "come8Odds",
      bet: 0,
      top: 75,
      left: 286,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come9Odds: {
      key: "come9Odds",
      bet: 0,
      top: 75,
      left: 358,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come12Odds: {
      key: "come12Odds",
      bet: 0,
      top: 75,
      left: 430,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come13Odds: {
      key: "come13Odds",
      bet: 0,
      top: 75,
      left: 502,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come14Odds: {
      key: "come14Odds",
      bet: 0,
      top: 75,
      left: 574,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come15Odds: {
      key: "come15Odds",
      bet: 0,
      top: 75,
      left: 646,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come16Odds: {
      key: "come16Odds",
      bet: 0,
      top: 75,
      left: 718,
      minbet: 1,
      maxbet: 0,
      betable: false,
   },
   come: {
      key: "come",
      bet: 0,
      top: 175,
      left: 70,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   field: {
      key: "field",
      bet: 0,
      top: 260,
      left: 390,
      minbet: 1,
      maxbet: 500,
      betable: true,
   },
   passLine: {
      key: "passLine",
      bet: 0,
      top: 315,
      left: 70,
      minbet: 1,
      maxbet: 500,
      betable: true,
   },
   passLineOdds: {
      key: "passLineOdds",
      bet: 0,
      top: 340,
      left: 140,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   hard1011: {
      key: "hard1011",
      bet: 0,
      top: 170,
      left: 580,
      minbet: 1,
      maxbet: 500,
      betable: true,
   },
   hard222: {
      key: "hard222",
      bet: 0,
      top: 210,
      left: 580,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   hard555: {
      key: "hard555",
      bet: 0,
      top: 210,
      left: 680,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   hard333: {
      key: "hard333",
      bet: 0,
      top: 255,
      left: 580,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   hard444: {
      key: "hard444",
      bet: 0,
      top: 255,
      left: 680,
      minbet: 1,
      maxbet: 500,
      betable: false,
   },
   hard111: {
      key: "hard111",
      bet: 0,
      top: 297,
      left: 580,
      minbet: 1,
      maxbet: 500,
      betable: true,
   },
   hard666: {
      key: "hard666",
      bet: 0,
      top: 297,
      left: 680,
      minbet: 1,
      maxbet: 500,
      betable: true,
   },
   anyCraps: {
      key: "anyCraps",
      bet: 0,
      top: 338,
      left: 680,
      minbet: 1,
      maxbet: 500,
      betable: true,
   },
};

export default data;
