import Game from "./game/Game";
import "./app.css";

function App() {
   return (
      <div className="App">
         <Game />
      </div>
   );
}

export default App;
