const Puck = (props) => {
   const style = {
      position: "absolute",
      width: 35,
      height: 30,
      top: props.top + "px",
      left: props.left + "px",
      backgroundRepeat: "no-repeat",
      backgroundImage: "url('/img/puck.png')",
      backgroundPosition: "0px " + (props.value === 0 ? 0 : -30) + "px",
      zIndex: 30,
   };

   return <div style={{ ...style }}></div>;
};

export default Puck;
