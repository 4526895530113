const Dice = (props) => {
   const values = {
      1: 0,
      2: -45,
      3: -90,
      4: -135,
      5: -180,
      6: -225,
   };

   const style = {
      display: props.display,
      position: "absolute",
      top: props.top + "px",
      left: props.left + "px",
      width: 45,
      height: 45,
      zIndex: 60,
      overflow: "hidden",
      backgroundRepeat: "no-repeat",
      backgroundImage: "url('/img/dice.png')",
      backgroundPosition: "0px " + (values[props.value] || values[1]) + "px",
   };

   return <div style={{ ...style }}></div>;
};

export default Dice;
