import { useRef, useState } from "react";
import Board from "./comps/Board";
import Control from "./comps/Control";
import "./css/game.css";

const Game = () => {
   const board = useRef();

   const [balance, setBalance] = useState(10000);
   const [wager, setWager] = useState(0);
   const [wins, setWins] = useState(0);
   const [chip, setChip] = useState(1);

   function diceRoll() {
      board.current.diceRoll();
   }

   function clearBets() {
      board.current.clearBets();
   }

   return (
      <div className="game">
         <div className="game-box">
            <Board ref={board} balance={balance} wager={wager} chip={chip} setBalance={setBalance} setWager={setWager} setWins={setWins} />
            <Control balance={balance} wager={wager} wins={wins} chip={chip} diceRoll={diceRoll} setChip={setChip} clearBets={clearBets} />
         </div>
      </div>
   );
};

export default Game;
