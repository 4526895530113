const Chip = (props) => {
   const coins = {
      1: -250,
      5: -300,
      25: -350,
      100: -400,
      500: -450,
      1000: -500,
   };

   const style = {
      position: "absolute",
      width: 50,
      height: 45,
      textAlign: "center",
      top: props.top + "px",
      left: props.left + "px",
      backgroundRepeat: "no-repeat",
      backgroundImage: "url('/img/chip.png')",
      transform: "scale(" + (props.scale || 1) + ")",
      backgroundPosition: (coins[props.value] || coins[1]) + "px 0",
      zIndex: 50,
      borderRadius: "50%",
      pointerEvents: "none",
      border: "2px solid #0000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "top 0.4s ease 0s, left 0.4s ease 0s",
   };

   const offStyle = { borderRadius: "50%", width: "70%", height: "60%", background: "#fac521", fontWeight: "bold" };

   return <div style={{ ...style }}>{props.off && <div style={offStyle}>Off</div>}</div>;
};

export default Chip;
