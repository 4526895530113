import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import Dice from "./Dice";

const RollDice = forwardRef((props, ref) => {
   const [diceDisplay, setDiceDisplay] = useState("none");

   const [dice1, setDice1] = useState({
      value: 1,
      top: 160,
      left: 800,
   });

   const [dice2, setDice2] = useState({
      value: 1,
      top: 210,
      left: 800,
   });

   const [dice3, setDice3] = useState({
      value: 1,
      top: 260,
      left: 800,
   });

   useImperativeHandle(ref, () => ({
      async diceRoll() {
         let num1 = 1;
         let num2 = 1;
         let num3 = 1;

         setDiceDisplay("block");
         setDice1({ value: num1, top: 160, left: 800 });
         setDice2({ value: num2, top: 210, left: 800 });
         setDice3({ value: num3, top: 260, left: 800 });

         let intv1 = Math.floor(Math.random() * (60 - 50)) + 50;
         let intv2 = Math.floor(Math.random() * (60 - 50)) + 50;
         let intv3 = Math.floor(Math.random() * (60 - 50)) + 50;

         let dir1 = 1;
         let accel1 = 100;
         let interval1 = setInterval(() => {
            num1 = Math.floor(Math.random() * 6) + 1;
            if (accel1 > 10) accel1 -= 5;
            setDice1((pos) => {
               if (dir1 === 1 && pos.left < 60) dir1 = -1;
               return { value: num1, top: pos.top, left: pos.left - accel1 * dir1 };
            });
         }, intv1);

         let dir2 = 1;
         let accel2 = 100;
         let interval2 = setInterval(() => {
            num2 = Math.floor(Math.random() * 6) + 1;
            if (accel2 > 10) accel2 -= 5;
            setDice2((pos) => {
               if (dir2 === 1 && pos.left < 60) dir2 = -1;
               return { value: num2, top: pos.top, left: pos.left - accel2 * dir2 };
            });
         }, intv2);

         let dir3 = 1;
         let accel3 = 100;
         let interval3 = setInterval(() => {
            num3 = Math.floor(Math.random() * 6) + 1;
            if (accel3 > 10) accel3 -= 5;
            setDice3((pos) => {
               if (dir3 === 1 && pos.left < 60) dir3 = -1;
               return { value: num3, top: pos.top, left: pos.left - accel3 * dir3 };
            });
         }, intv3);

         let time = Math.floor(Math.random() * (1500 - 1000)) + 1000;
         await timeout(time);
         clearInterval(interval1);
         clearInterval(interval2);
         clearInterval(interval3);
         return { val: num1 + num2 + num3, vals: [num1, num2, num3] };
      },
   }));

   function timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
   }

   return (
      <div>
         <Dice top={dice1.top} left={dice1.left} display={diceDisplay} value={dice1.value} />
         <Dice top={dice2.top} left={dice2.left} display={diceDisplay} value={dice2.value} />
         <Dice top={dice3.top} left={dice3.left} display={diceDisplay} value={dice3.value} />
      </div>
   );
});

export default RollDice;
