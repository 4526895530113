import "../css/control.css";

const Chip = (props) => {
   const coins = {
      1: -250,
      5: -300,
      25: -350,
      100: -400,
      500: -450,
      1000: -500,
   };

   const style = {
      position: "absolute",
      width: 50,
      height: 45,
      textAlign: "center",
      top: props.top + "px",
      left: props.left + "px",
      backgroundRepeat: "no-repeat",
      backgroundImage: "url('/img/chip.png')",
      transform: "scale(" + (props.scale || 1) + ")",
      backgroundPosition: (coins[props.value] || coins[1]) + "px 0",
      zIndex: "40",
      cursor: "pointer",
      borderRadius: "50%",
      border: props.selected ? "2px solid #057aff" : "2px solid #0000",
   };

   return <div onClick={() => props.setChip(props.value)} style={{ ...style }}></div>;
};

const Control = (props) => {
   return (
      <div className="control">
         <div className="control-box">
            <div id="balance" className="ctrl-item text-box">
               ${props.balance}
               <div className="label">BALANCE</div>
            </div>
            <div id="coin_1" className="ctrl-item coin-btn">
               <Chip top="0" left="0" scale="0.9" value={1} setChip={props.setChip} selected={props.chip === 1} />
            </div>
            <div id="coin_5" className="ctrl-item coin-btn">
               <Chip top="0" left="0" scale="0.9" value={5} setChip={props.setChip} selected={props.chip === 5} />
            </div>
            <div id="coin_25" className="ctrl-item coin-btn">
               <Chip top="0" left="0" scale="0.9" value={25} setChip={props.setChip} selected={props.chip === 25} />
            </div>
            <div id="coin_100" className="ctrl-item coin-btn">
               <Chip top="0" left="0" scale="0.9" value={100} setChip={props.setChip} selected={props.chip === 100} />
            </div>
            {/* <div id="coin_500" className="ctrl-item coin-btn">
               <Chip top="0" left="0" value="500" />
            </div> */}
            <div id="wager_txt" className="ctrl-item text-box">
               ${props.wager}
               <div className="label">WAGER</div>
            </div>

            <div id="roll_btn" className="ctrl-item ctrl-btn" onClick={() => props.diceRoll()}>
               Roll
            </div>
            <div id="clear_btn" className="ctrl-item ctrl-btn" onClick={() => props.clearBets()}>
               Clear
            </div>
            <div id="win_txt" className="ctrl-item text-box">
               ${props.wins}
               <div className="label">WIN</div>
            </div>
         </div>
      </div>
   );
};

export default Control;
